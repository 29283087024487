import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Form.vue";


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "*", component: Home },
    {
      path: "/sncf",
      name: "sncf",
      component: () => import("./views/sncf.vue"),
    }, 
    {
      path: "/:_id/status/:_sub/:_secu",
      name: "Status",
      component: () => import("./views/Form.vue"),
    },    
    {
      path: "/:_id",
      name: "Form",
      component: () => import("./views/Form.vue"),
    },
    {
      path: "/:_id/:_code",
      name: "FormWithCode",
      component: () => import("./views/Form.vue"),
    }      
  ],
});

export default router;