<template>
  <div id="app">
    <headerElem></headerElem>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <FooterElem></FooterElem>
  </div>
</template>

<script>
import HeaderElem from "@/components/Header";
import FooterElem from "@/components/Footer";

export default {
  name: "App",
  data: function () {
    return {
      menu: false,
    };
  },
  mounted() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let message = {
      height: document.body.scrollHeight,
      width: document.body.scrollWidth,
      scrollTop: scrollTop,
    };
    window.top.postMessage(message, "*");
  },
  components: {
    HeaderElem,
    FooterElem,
  },
};
</script>
